"use client";

import { useState } from "react";
import Link from "next/link";
import { supportNumber, supportsNumberHref } from "@/shared/constants/constants";
import styles from "./index.module.scss";
import dynamic from "next/dynamic";
const CallbackPopup = dynamic(() => import("@/shared/components/popups/CallbackPopup"), {
  ssr: false
});
interface IHeaderInfo {
  customClassName?: string;
}
const Info = (props: IHeaderInfo) => {
  const {
    customClassName
  } = props;
  const [show, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => {
    setShowModal(!show);
  };
  const handleCloseModal = (arg: boolean) => {
    setShowModal(arg);
  };
  return <div className={`${styles.wrap} ${customClassName}`} data-sentry-component="Info" data-sentry-source-file="index.tsx">
      {show && <CallbackPopup isOpen={show} onClose={handleCloseModal} />}
      <div className={styles.text_wrap}>
        <p className={styles.text}>7:00-22:00</p>
        <p className={styles.time}>по МСК</p>
      </div>
      <div className={styles.callback_container}>
        <Link prefetch={false} href={supportsNumberHref} className={styles.link} onClick={() => ym(42989679, "reachGoal", "clickOnTel")} data-sentry-element="Link" data-sentry-source-file="index.tsx">
          {supportNumber}
        </Link>
        <button className={styles.callback} onClick={handleShowModal}>
          служба поддержки
        </button>
      </div>
    </div>;
};
export default Info;